import * as React from "react";
import classNames from "classnames";
import { Icon, IIconType } from "../Icons";
import { IconButton } from "../IconButton";
import { IconFloatLabel } from "./iconFloatLabel";
import s from "./style.module.scss";

interface IService {
	id: string;
	title: string;
	icon: IIconType;
}

interface IProps {
	services: IService[];
	selecteds: string[];
	className?: string;
	onSelect: (id: string) => void;
}

export const Cellphone = (props: IProps) => (
	<div className={classNames(s.cellphone, props.className)}>
		<div className={s.content}>
			{props.services.map((service, key) => (
				<IconFloatLabel
					label={service.title}
					position={key % 2 !== 0 ? "right" : "left"}
					key={key}
				>
					<IconButton
						onClick={() => props.onSelect(service.id)}
						className={classNames(
							s.btn,
							props.selecteds.includes(service.id)
								? s.active
								: "",
						)}
					>
						<Icon type={service.icon} />
					</IconButton>
				</IconFloatLabel>
			))}
		</div>
		<div className={s.footer} />
	</div>
);
