import * as React from "react";

export const BarsCodeSvg = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="1em"
		height="1em"
		viewBox="0 0 512 512"
		fill="currentColor"
	>
		<path d="M62.7 88.82H10.44A10.45 10.45 0 0 0 0 99.27v52.24a10.45 10.45 0 1 0 20.9 0v-41.8h41.8a10.45 10.45 0 1 0 0-20.9zM501.55 88.82h-52.24a10.45 10.45 0 1 0 0 20.9h41.8v41.79a10.45 10.45 0 1 0 20.89 0V99.27c0-5.78-4.68-10.45-10.45-10.45zM501.55 350.04a10.45 10.45 0 0 0-10.45 10.45v41.8h-41.8a10.45 10.45 0 1 0 0 20.9h52.25c5.77 0 10.45-4.68 10.45-10.45v-52.25c0-5.77-4.68-10.45-10.45-10.45zM62.7 402.29H20.9v-41.8a10.45 10.45 0 1 0-20.9 0v52.24c0 5.78 4.68 10.45 10.45 10.45h52.24a10.45 10.45 0 1 0 0-20.9z" />
		<path d="M470.2 370.94V141.06a10.45 10.45 0 1 0-20.9 0v229.88a10.45 10.45 0 1 0 20.9 0zM408.82 130.61a10.45 10.45 0 0 0-10.45 10.45v180.42a10.45 10.45 0 1 0 20.9 0V141.06c0-5.77-4.68-10.45-10.45-10.45zM408.82 358.06a10.45 10.45 0 0 0-10.45 10.44v2.44a10.45 10.45 0 1 0 20.9 0v-2.44c0-5.77-4.68-10.44-10.45-10.44zM357.88 130.61a10.45 10.45 0 0 0-10.45 10.45v180.42a10.45 10.45 0 1 0 20.9 0V141.06c0-5.77-4.68-10.45-10.45-10.45zM357.88 358.06a10.45 10.45 0 0 0-10.45 10.44v2.44a10.45 10.45 0 1 0 20.9 0v-2.44c0-5.77-4.68-10.44-10.45-10.44zM306.94 130.61a10.45 10.45 0 0 0-10.45 10.45v180.42a10.45 10.45 0 1 0 20.9 0V141.06c0-5.77-4.68-10.45-10.45-10.45zM306.94 358.06a10.45 10.45 0 0 0-10.45 10.44v2.44a10.45 10.45 0 1 0 20.9 0v-2.44c0-5.77-4.68-10.44-10.45-10.44zM256 130.61a10.45 10.45 0 0 0-10.45 10.45v180.42a10.45 10.45 0 1 0 20.9 0V141.06c0-5.77-4.68-10.45-10.45-10.45zM256 358.06a10.45 10.45 0 0 0-10.45 10.44v2.44a10.45 10.45 0 1 0 20.9 0v-2.44c0-5.77-4.68-10.44-10.45-10.44zM205.06 130.61a10.45 10.45 0 0 0-10.45 10.45v180.42a10.45 10.45 0 1 0 20.9 0V141.06c0-5.77-4.68-10.45-10.45-10.45zM205.06 358.06a10.45 10.45 0 0 0-10.45 10.44v2.44a10.45 10.45 0 1 0 20.9 0v-2.44c0-5.77-4.68-10.44-10.45-10.44zM154.12 130.61a10.45 10.45 0 0 0-10.45 10.45v180.42a10.45 10.45 0 1 0 20.9 0V141.06c0-5.77-4.68-10.45-10.45-10.45zM154.12 358.06a10.45 10.45 0 0 0-10.45 10.44v2.44a10.45 10.45 0 1 0 20.9 0v-2.44c0-5.77-4.68-10.44-10.45-10.44zM103.18 130.61a10.45 10.45 0 0 0-10.45 10.45v180.42a10.45 10.45 0 1 0 20.9 0V141.06c0-5.77-4.67-10.45-10.45-10.45zM103.18 358.06a10.45 10.45 0 0 0-10.45 10.44v2.44a10.45 10.45 0 1 0 20.9 0v-2.44c0-5.77-4.67-10.44-10.45-10.44zM62.7 370.94V141.06a10.45 10.45 0 1 0-20.9 0v229.88a10.45 10.45 0 1 0 20.9 0z" />
	</svg>
);
