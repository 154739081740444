import * as React from "react";
import classNames from "classnames";
import { Icon } from "../../components/Icons";
import { Cellphone } from "../../components/Cellphone";

import theme from "../../themeConfig";
import s from "./style.module.scss";

export const ServicesSection = () => {
	const firstServiceId = theme.servicesSection.services[0]
		? theme.servicesSection.services[0].id
		: "";
	const [services, setServices] = React.useState<string[]>([firstServiceId]);

	return (
		<section className={s.container}>
			<h2 className={s.title}>{theme.servicesSection.title}</h2>
			<div className={s.wrap_cell}>
				<div className={s.content_cell}>
					<Cellphone
						className={s.cellphone}
						selecteds={services}
						onSelect={(e) => setServices([e])}
						services={theme.servicesSection.services.map(
							(service) => ({
								id: service.id,
								title: service.shortTitle || service.title,
								icon: service.icon,
							}),
						)}
					/>

					<ul className={s.services_cell}>
						{theme.servicesSection.services.map((service) => (
							<li
								key={`${service.icon}-service`}
								className={classNames(
									s.services_cell_service,
									services.includes(service.id) && s.active,
								)}
							>
								<h3 className={s.services_cell_title}>
									{service.title}
								</h3>
								<p className={s.services_cell_description}>
									{service.description}
								</p>
							</li>
						))}
					</ul>
				</div>
			</div>

			<ul className={s.services}>
				{theme.servicesSection.services.map((service) => (
					<li
						key={`${service.icon}-service`}
						className={s.services_service}
					>
						<span className={s.service_icon}>
							<Icon type={service.icon} />
						</span>
						<h3 className={s.service_title}>{service.title}</h3>
						<p className={s.service_description}>
							{service.description}
						</p>
					</li>
				))}
			</ul>
		</section>
	);
};
