import * as React from "react";
import { StaticQuery, graphql } from "gatsby";
import Helmet from "react-helmet";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { HiBot } from "../components/HiBot";

import theme from "../themeConfig";
import "../assets/styles/global.scss";
import s from "./style.module.scss";

export const Layout: React.FunctionComponent = ({ children }) => (
	<StaticQuery
		query={graphql`
			query SiteTitleQuery {
				site {
					siteMetadata {
						title
					}
				}
			}
		`}
		render={() => (
			<>
				<Helmet>
					<link href={theme.fontFamilyUrl} rel="stylesheet" />
				</Helmet>
				<Header />
				<main className={s.container}>{children}</main>
				<Footer />
				<HiBot />
			</>
		)}
	/>
);
