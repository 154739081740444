import * as React from "react";
import classNames from "classnames";
import s from "./style.module.scss";

interface IProps {
	label: string;
	position?: "left" | "right";
}

export const IconFloatLabel: React.FunctionComponent<IProps> = (props) => (
	<div className={s.iconFloat}>
		<div>{props.children}</div>
		<div className={classNames(s.label, props.position)}>{props.label}</div>
	</div>
);

IconFloatLabel.defaultProps = {
	position: "left",
};
