import * as React from "react";

export const ServicesSvg = () => (
	<svg
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 512 512"
		width="1em"
		height="1em"
		fill="currentColor"
	>
		<path
			fillRule="evenodd"
			d="M371.9,144L475,171.1L501,68l-19.8-5.2l-16.9,64.3C421.1,57.4,344,11,256,11C120.7,11,11,120.7,11,256    c0,135.3,109.7,245,245,245c90.3,0,169.1-48.8,211.6-121.5l-17.7-9.6c-39.1,66.4-111.3,111-193.9,111    C131.8,480.9,31.1,380.2,31.1,256S131.8,31.1,256,31.1c83.3,0,156.1,45.3,194.9,112.7l-73.8-19.6L371.9,144z"
		/>
		<path
			fillRule="evenodd"
			d="m266.8,364.7v-18.7c17.7-1 31.2-6.2 41.6-15.6s15.6-21.9 15.6-36.4c0-15.6-4.2-27.1-13.5-34.4-9.4-8.3-22.9-14.6-42.7-18.7h-1v-47.9c13.5,2.1 25,6.2 36.4,14.6l16.7-22.9c-16.7-11.5-34.4-17.7-53.1-18.7v-12.5h-15.6v12.5c-15.6,1-29.2,6.2-39.6,15.6-10.4,9.4-15.6,21.9-15.6,36.4s4.2,26 13.5,33.3c8.3,7.3 22.9,13.5 41.6,17.7v48.9c-14.6-2.1-29.2-9.4-43.7-21.9l-18.7,21.9c18.7,16.7 39.6,26 62.5,28.1v18.7h15.6zm1-90.6c10.4,3.1 16.7,6.2 20.8,9.4 3.1,4.2 5.2,8.3 5.2,14.6s-2.1,10.4-7.3,14.6c-5.2,4.2-11.5,6.2-18.7,7.3v-45.9zm-36.4-44.7c-3.1-3.1-5.2-8.3-5.2-13.5 0-6.2 2.1-10.4 6.2-14.6 5.2-4.2 10.4-6.2 18.7-7.3v44.8c-10.4-3.2-16.6-6.3-19.7-9.4z"
		/>
	</svg>
);
