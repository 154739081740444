import * as React from "react";
import { Icon } from "../../components/Icons";
import Slider, { Settings } from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import theme from "../../themeConfig";
import s from "./style.module.scss";

export const OtherServicesSection = () => {
	return (
		<section className={s.container}>
			<div className={s.content}>
				<h3 className={s.title}>{theme.otherServicesSection.title}</h3>
				<p className={s.description}>
					{theme.otherServicesSection.description}
				</p>

				<div className={s.slick}>
					<Slider rows={2} {...settingsSlick}>
						{theme.otherServicesSection.services.map(
							(item, key) => (
								<div key={`${key}-slick`}>
									<div className={s.card}>
										<Icon type={item.icon as any} />
										<div className={s.card_title}>
											{item.title}
										</div>
										<div className={s.card_description}>
											{item.description}
										</div>
									</div>
								</div>
							),
						)}
					</Slider>
				</div>
			</div>
		</section>
	);
};

const settingsSlick: Settings = {
	dots: true,
	infinite: false,
	speed: 500,
	slidesToShow: 4,
	slidesToScroll: 1,
	arrows: false,
	swipeToSlide: true,
	responsive: [
		{
			breakpoint: 1050,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 1,
			},
		},
		{
			breakpoint: 800,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 1,
				centerMode: true,
			},
		},
		{
			breakpoint: 658,
			settings: {
				className: "center",
				centerMode: true,
				centerPadding: "30px",
				slidesToShow: 1,
				slidesToScroll: 1,
			},
		},
	],
};
