import * as React from "react";
import { Icon } from "../Icons";
import classNames from "classnames";

import theme from "../../themeConfig";
import s from "./style.module.scss";
const fareUrl =
	theme.footer.fareUrl &&
	require(`../../assets/docs/${theme.footer.fareUrl}`);

interface IWrapWithLinkProps {
	link?: string;
	newTab?: boolean;
}

export const Footer = () => (
	<footer className={s.footer}>
		<div className={s.content}>
			<div>
				<div className={s.title_wrap}>
					<h3 className={s.title}>{theme.footer.title}</h3>
				</div>

				{fareUrl && (
					<div>
						<h4 className={s.titleFares}>
							{theme.footer.titleFares}
						</h4>
						<a
							href={fareUrl}
							target="blank"
							rel="noopener noreferrer"
							className={classNames(s.btn, s.btn_fares)}
						>
							{theme.footer.btnFares}
						</a>
					</div>
				)}
			</div>

			<ul className={s.list_links}>
				{theme.footer.infos.map((info, key) => (
					<li className={s.list_links_item} key={`${key}-footer`}>
						<span className={s.item_icon}>
							<Icon type={info.icon as any} />
						</span>
						<div className={s.item_info}>
							<WrapWithLink link={info.link} newTab={info.newTab}>
								<div className={s.item_label}>{info.label}</div>

								{info.telNumber && (
									<div className={s.item_value}>
										{info.telNumber}
									</div>
								)}
								{info.value && (
									<div className={s.item_value}>
										{info.value}
									</div>
								)}
							</WrapWithLink>
						</div>
					</li>
				))}
			</ul>
		</div>
	</footer>
);

const WrapWithLink: React.FunctionComponent<IWrapWithLinkProps> = (props) => {
	if (!props.link) return <>{props.children}</>;

	return (
		<a
			href={props.link}
			target={props.newTab ? "_blank" : undefined}
			rel="noopener noreferrer"
		>
			{props.children}
		</a>
	);
};
