import * as React from "react";
import * as queryString from "query-string";

import "./style.scss";
import themeConfig from "../../themeConfig";

export const HiBot = () => {
	const params: any = {
		token:
			themeConfig.mainSection.hibotToken ||
			"1bc01b47-d1b0-45dc-9cfc-dcfee1771752",
		widget: true,
		tab: true,
		top: 40,
		text: "Oi",
		textcolor: "ffffff",
		bgcolor: themeConfig.hibotColor || "152336",
		from: "bottomright",
		widgetType: "circle",
		iconId: "",
		origem:
			themeConfig.mainSection.hibotOrigin ||
			`${themeConfig.pageTitle}-deslogadada`,
	};

	console.log(params);

	if (themeConfig.mainSection.hibotTemplate) {
		params.template = themeConfig.mainSection.hibotTemplate;
	}

	const init = () => {
		console.log("oi");
		const script = document.createElement("script");
		console.log(queryString.stringify);
		script.src = `https://cdn-bot.hiplatform.com/dtbot.js?${queryString.stringify(
			params,
		)}`;
		console.log(script.src);
		script.async = true;
		script.id = "dtbot-script";

		document.body.appendChild(script);
	};

	React.useEffect(() => {
		init();
	}, []);

	return null;
};
