import { IIconType } from "../components/Icons";
import { allobankTheme } from "./themes/allobank";
import { ativoTheme } from "./themes/ativo";
import { avancardTheme } from "./themes/avancard";
import { bbnkTheme } from "./themes/bbnk";
import { bdlTheme } from "./themes/bdl";
import { ciaTheme } from "./themes/cia";
import { contaeduTheme } from "./themes/contaedu";
import { coxaTheme } from "./themes/coxa";
import { estudanteTheme } from "./themes/estudante";
import { exataTheme } from "./themes/exata";
import { flowinsTheme } from "./themes/flowins";
import { hubnkTheme } from "./themes/hubnk";
import { hxmaisTheme } from "./themes/hxmais";
import { iganheTheme } from "./themes/iganhe";
import { magpayTheme } from "./themes/magpay";
import { maximaTheme } from "./themes/maxima";
import { meridianTheme } from "./themes/meridian";
import { meuhelpTheme } from "./themes/meuhelp";
import { meuhelpotbTheme } from "./themes/meuhelpotb";
import { nerdTheme } from "./themes/nerd";
import { oraTheme } from "./themes/ora";
import { partyouTheme } from "./themes/partyou";
import { pitaiaTheme } from "./themes/pitaia";
import { singuTheme } from "./themes/singu";
import { skadiTheme } from "./themes/skadi";
import { slimBankTheme } from "./themes/slimbank";
import { smartTheme } from "./themes/smart";
import { startTheme } from "./themes/start";
import { sysdataTheme } from "./themes/sysdata";
import { tipayTheme } from "./themes/tipay";
import { tipicTheme } from "./themes/tipic";
import { venetoTheme } from "./themes/veneto";
import { vivipayTheme } from "./themes/vivipay";
import { wTheme } from "./themes/w";
import { whitelabelTheme } from "./themes/whitelabel";
import { wpagTheme } from "./themes/wpag";
import { xpagoTheme } from "./themes/xpago";
import { aragonTheme } from "./themes/aragon";
import { ceoPagTheme } from "./themes/ceopag";
import { bluBankTheme } from "./themes/blubank";
import { edanBankTheme } from "./themes/edanbank";
import { suitBankTheme } from "./themes/suitbank";
import { vantageTheme } from "./themes/vantage";
import { weNovTheme } from "./themes/wenov";
// import axios from "axios";
// import { async } from "q";

// import { async } from "q";

export interface ITheme {
	pageTitle: string;
	metaDescription: string;
	fontFamilyUrl: string;
	hibotColor: string;
	header: {
		logo: string;
		mobileLogo: string;
		primaryBtnText: string;
		secondaryBtnText: string;
	};
	mainSection: {
		title: string;
		subTitle?: string;
		description: string;
		playStoreLink: string;
		appleStoreLink: string;
		ibLink: string;
		hibotToken: string;
		hibotTemplate: string;
		hibotOrigin: string;
		backgroundImage: string;
	};
	servicesSection: {
		title: string;
		services: {
			id: string;
			title: string;
			shortTitle?: string;
			description: string;
			icon: IIconType;
		}[];
	};
	otherServicesSection: {
		title: string;
		description: string;
		services: {
			title: string;
			description: string;
			icon: IIconType;
		}[];
	};
	footer: {
		title: string;
		titleFares?: string;
		fareUrl?: string;
		btnFares?: string;
		infos: {
			icon: IIconType;
			label: string;
			telNumber?: string;
			value?: string;
			link?: string;
			newTab?: boolean;
		}[];
	};
}

const getThemeByPartnerId = () => {
	console.log("slug:", JSON.stringify(process.env.GATSBY_PARTNER_SLUG));
	switch (process.env.GATSBY_PARTNER_SLUG) {
		case "maxima":
			return maximaTheme;
		case "meuhelp":
			return meuhelpTheme;
		case "sysdata":
			return sysdataTheme;
		case "xpago":
			return xpagoTheme;
		case "pitaia":
			return pitaiaTheme;
		case "iganhe":
			return iganheTheme;
		case "wpag":
			return wpagTheme;
		case "singu":
			return singuTheme;
		case "hubnk":
			return hubnkTheme;
		case "allobank":
			return allobankTheme;
		case "w":
			return wTheme;
		case "whitelabel":
			return whitelabelTheme;
		case "bbnk":
			return bbnkTheme;
		case "slimbank":
			return slimBankTheme;
		case "veneto":
			return venetoTheme;
		case "coxa":
			return coxaTheme;
		case "nerd":
			return nerdTheme;
		case "tipay":
			return tipayTheme;
		case "estudante":
			return estudanteTheme;
		case "flowins":
			return flowinsTheme;
		case "tipic":
			return tipicTheme;
		case "ora":
			return oraTheme;
		case "magpay":
			return magpayTheme;
		case "smart":
			return smartTheme;
		case "contaedu":
			return contaeduTheme;
		case "partyou":
			return partyouTheme;
		case "cia":
			return ciaTheme;
		case "vivipay":
			return vivipayTheme;
		case "meuhelpotb":
			return meuhelpotbTheme;
		case "hxmais":
			return hxmaisTheme;
		case "skadi":
			return skadiTheme;
		case "avancard":
			return avancardTheme;
		case "exata":
			return exataTheme;
		case "start":
			return startTheme;
		case "ativo":
			return ativoTheme;
		case "bdl":
			return bdlTheme;
		case "meridian":
			return meridianTheme;
		case "aragon":
			return aragonTheme;
		case "ceopag":
			return ceoPagTheme;
		case "blubank":
			return bluBankTheme;
		case "edanbank":
			return edanBankTheme;
		case "suitbank":
			return suitBankTheme;
		case "vantage":
			return vantageTheme;
		case "wenov":
			return weNovTheme;
		default:
			// Descomentar essa linha se precisar testar localmente
			return bbnkTheme;
		// throw new Error("Partner não configurado");
	}
};

// async function getURL() {
// 	const res = await axios(apiUrl + 25);
// 	return await res.data;
// }

// getURL().then(res => console.log(res));

const theme = getThemeByPartnerId();

if (process.env.GATSBY_ENVIRONMENT === "hml") {
	theme.mainSection.ibLink = `https://hml-sandbox-internetbanking-${process.env.GATSBY_PARTNER_SLUG}.bbnk.com.br`;
} else if (process.env.GATSBY_ENVIRONMENT === "cubos-hml") {
	theme.mainSection.ibLink = `https://hml-${process.env.GATSBY_PARTNER_SLUG}-internetbanking.bbnk.cubos.io`;
} else if (process.env.GATSBY_ENVIRONMENT !== "prd") {
	theme.mainSection.ibLink = `https://${process.env.GATSBY_ENVIRONMENT}-${process.env.GATSBY_PARTNER_SLUG}-internetbanking.bbnk.cubos.io`;
}

export default theme;
