import * as React from "react";

export const PaymentSvg = () => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 22"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g
			id="Page-1"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
		>
			<g
				id="Artboard"
				transform="translate(-389.000000, -73.000000)"
				fill="currentColor"
				fillRule="nonzero"
			>
				<g
					id="invoice-(1)"
					transform="translate(389.000000, 73.000000)"
				>
					<path
						d="M15.8697917,4.58751685 L11.4253333,0.130574087 C11.342,0.0470066713 11.2291667,0 11.1110833,0 L1.77775,0 C0.79775,0 0,0.79953125 0,1.78278546 L0,19.6105147 C0,20.5937272 0.79775,21.3932584 1.77779167,21.3932584 L14.22225,21.3932584 C15.20225,21.3932584 16,20.5937272 16,19.610473 L16,4.90260779 C16,4.78423455 15.953125,4.67108427 15.8697917,4.58751685 Z M11.5555417,1.52163729 L14.482625,4.45694277 L12.4444167,4.45694277 C11.9539583,4.45694277 11.5555417,4.05694733 11.5555417,3.56557093 L11.5555417,1.52163729 L11.5555417,1.52163729 Z M15.111125,19.610473 C15.111125,20.1018494 14.7126667,20.5018448 14.22225,20.5018448 L1.77779167,20.5018448 C1.28733333,20.5018448 0.888916667,20.1018494 0.888916667,19.610473 L0.888916667,1.78278546 C0.888916667,1.29140906 1.287375,0.891413624 1.77779167,0.891413624 L10.6666667,0.891413624 L10.6666667,3.56557093 C10.6666667,4.54878336 11.4644167,5.34831461 12.4444583,5.34831461 L15.111125,5.34831461 L15.111125,19.610473 Z"
						id="Shape"
					/>
					<path
						d="M12.888875,18 L3.111125,18 C2.86545833,18 2.66666667,18.1993501 2.66666667,18.4457068 C2.66666667,18.6920636 2.86545833,18.8914136 3.111125,18.8914136 L12.8889167,18.8914136 C13.1345833,18.8914136 13.333375,18.6920636 13.333375,18.4457068 C13.333375,18.1993501 13.1345417,18 12.888875,18 Z"
						id="Path"
					/>
					<path
						d="M4,7.13110007 L3.111125,7.13110007 C2.86545833,7.13110007 2.66666667,7.33045014 2.66666667,7.57680688 C2.66666667,7.82316362 2.86545833,8.02251369 3.111125,8.02251369 L3.55558333,8.02251369 C3.55558333,8.26887044 3.754375,8.46822051 4.00004167,8.46822051 C4.24570833,8.46822051 4.4445,8.26887044 4.4445,8.02251369 L4.4445,7.94040871 C4.96066667,7.7557665 5.33333333,7.26568539 5.33333333,6.68539326 C5.33333333,5.94807795 4.73525,5.34831461 4,5.34831461 C3.75520833,5.34831461 3.55554167,5.1485467 3.55554167,4.90260779 C3.55554167,4.65671067 3.75520833,4.45690098 4,4.45690098 L4.888875,4.45690098 C5.13454167,4.45690098 5.33333333,4.25755091 5.33333333,4.01119417 C5.33333333,3.76483743 5.13454167,3.56548736 4.888875,3.56548736 L4.44441667,3.56548736 C4.44441667,3.31913062 4.245625,3.11978055 3.99995833,3.11978055 C3.75429167,3.11978055 3.5555,3.31913062 3.5555,3.56548736 L3.5555,3.64759235 C3.03933333,3.83223455 2.66666667,4.32231566 2.66666667,4.90260779 C2.66666667,5.6399231 3.26475,6.23968645 4,6.23968645 C4.24479167,6.23968645 4.44445833,6.43945435 4.44445833,6.68539326 C4.44445833,6.93133216 4.24479167,7.13110007 4,7.13110007 Z"
						id="Path"
					/>
				</g>
			</g>
		</g>
	</svg>
);
