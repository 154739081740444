import * as React from "react";

export const LockedSvg = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="1em"
		height="1em"
		viewBox="0 0 54 54"
		fill="currentColor"
	>
		<path d="M43 20.11V14.5C43 6.5 35.82 0 27 0S11 6.5 11 14.5v5.61c-3.4.59-6 3.55-6 7.12v19.54A7.24 7.24 0 0 0 12.23 54h29.54A7.24 7.24 0 0 0 49 46.77V27.23c0-3.57-2.6-6.53-6-7.12zM13 14.5C13 7.6 19.28 2 27 2s14 5.6 14 12.5V20H13v-5.5zm34 32.27A5.24 5.24 0 0 1 41.77 52H12.23A5.24 5.24 0 0 1 7 46.77V27.23A5.24 5.24 0 0 1 12.23 22h29.54A5.24 5.24 0 0 1 47 27.23v19.54z" />
		<path d="M27 28a4 4 0 0 0-4 4v6a4 4 0 0 0 8 0v-6a4 4 0 0 0-4-4zm2 10a2 2 0 0 1-4 0v-6a2 2 0 0 1 4 0v6z" />
	</svg>
);
