import * as React from "react";

export const ChartSvg = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 60 60"
		width="1em"
		height="1em"
		fill="currentColor"
	>
		<path d="M59 55.5v-39H47v39h-3v-31H32v31h-3v-23H17v23h-3v-14H2v14H1a1 1 0 1 0 0 2h58a1 1 0 1 0 0-2zm-55 0v-12h8v12H4zm15 0v-21h8v21h-8zm15 0v-29h8v29h-8zm15 0v-37h8v37h-8zM8.03 27.83a1 1 0 0 0 .5-.13L44.8 6.76l-2.27 4.99a1 1 0 1 0 1.82.82l3.56-7.83.02-.07.04-.16c0-.03.02-.06.03-.08v-.04l-.01-.1a1 1 0 0 0-.02-.17L47.94 4l-.05-.1-.02-.07-.06-.06-.06-.1-.09-.07a1.03 1.03 0 0 0-.13-.1l-.09-.06-.03-.02-.07-.02a.97.97 0 0 0-.17-.04l-.07-.02-8.57-.83a1 1 0 1 0-.2 1.99l5.47.52L7.53 25.96a1 1 0 0 0 .5 1.87z" />
	</svg>
);
