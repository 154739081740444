import * as React from "react";

export const ChatSvg = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="1em"
		height="1em"
		viewBox="0 0 612 612"
		fill="currentColor"
	>
		<path d="M401.63 325.13H210.38a19.13 19.13 0 0 0 0 38.25h191.25a19.13 19.13 0 0 0 0-38.25zm38.25-114.75H172.13a19.13 19.13 0 0 0 0 38.25h267.75a19.13 19.13 0 0 0 0-38.25zM306 0C137.01 0 0 119.88 0 267.75c0 84.51 44.85 159.75 114.75 208.83V612l134.05-81.34a350.9 350.9 0 0 0 57.2 4.84c169 0 306-119.87 306-267.75S475 0 306 0zm0 497.25a309.5 309.5 0 0 1-64.64-7.02l-90.04 54.12 1.2-88.7C83.5 414.13 38.25 345.51 38.25 267.75c0-126.74 119.88-229.5 267.75-229.5 147.88 0 267.75 102.76 267.75 229.5S453.87 497.25 306 497.25z" />
	</svg>
);
