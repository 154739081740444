import * as React from "react";
import { WalletSvg } from "./svgs/wallet";
import { CreditCardSvg } from "./svgs/creditCard";
import { ArrowsSvg } from "./svgs/arrows";
import { ChartSvg } from "./svgs/chart";
import { ChatSvg } from "./svgs/chat";
import { ChatHelpSvg } from "./svgs/chatHelp";
import { TelephoneSvg } from "./svgs/telephone";
import { BarsCodeSvg } from "./svgs/barsCode";
import { LockedSvg } from "./svgs/locked";
import { PaymentSvg } from "./svgs/payment";
import { InovationSvg } from "./svgs/inovation";
import { PraticalSvg } from "./svgs/pratical";
import { ServicesSvg } from "./svgs/services";

interface IProps {
	type: IIconType;
}

export type IIconType =
	| "wallet"
	| "creditCard"
	| "arrows"
	| "chart"
	| "chat"
	| "chatHelp"
	| "telephone"
	| "barsCode"
	| "locked"
	| "payment"
	| "inovation"
	| "pratical"
	| "services";

const icons = {
	wallet: WalletSvg,
	creditCard: CreditCardSvg,
	arrows: ArrowsSvg,
	chart: ChartSvg,
	chat: ChatSvg,
	chatHelp: ChatHelpSvg,
	telephone: TelephoneSvg,
	barsCode: BarsCodeSvg,
	locked: LockedSvg,
	payment: PaymentSvg,
	inovation: InovationSvg,
	pratical: PraticalSvg,
	services: ServicesSvg,
};

export const Icon = ({ type }: IProps) => {
	const CurrentIcon = icons[type];

	return <CurrentIcon />;
};
