import * as React from "react";
import classNames from "classnames";

import theme from "../../themeConfig";
import s from "./style.module.scss";

const desktopImage = require(`../../assets/images/${theme.header.logo}`);
const mobileImage =
	theme.header.mobileLogo &&
	require(`../../assets/images/${theme.header.mobileLogo}`);
export const Header: React.FunctionComponent = () => (
	<header className={s.header}>
		<div className={s.content}>
			<div className={s.logo}>
				<picture>
					<source srcSet={mobileImage} media="(max-width: 768px)" />
					<img className={s.image} src={desktopImage} />
				</picture>
			</div>
			<div className={s.buttons}>
				<a
					href={`${theme.mainSection.ibLink}/register`}
					className={classNames(s.btn, s.btn_primary)}
				>
					{theme.header.primaryBtnText}
				</a>
				<a
					href={`${theme.mainSection.ibLink}/login`}
					className={classNames(s.btn, s.btn_secondary)}
				>
					{theme.header.secondaryBtnText}
				</a>
			</div>
		</div>
	</header>
);
