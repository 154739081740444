import * as React from "react";
import theme from "../../themeConfig";
import s from "./style.module.scss";

const imageBg = require(`../../assets/images/${theme.mainSection.backgroundImage}`);

export const MainSection: React.FunctionComponent = () => (
	<section
		className={s.container}
		style={{ backgroundImage: `url(${imageBg})` }}
	>
		<div className={s.content}>
			<h1 className={s.title}>{theme.mainSection.title}</h1>
			<h1 className={s.subTitle}>{theme.mainSection.subTitle}</h1>
			<h2 className={s.description}>{theme.mainSection.description}</h2>
			<div className={s.stores}>
				{!!theme.mainSection.playStoreLink ? (
					<a
						href={theme.mainSection.playStoreLink}
						target="_blank"
						rel="noopener noreferrer"
						title="Baixar na play store"
					>
						<img
							src={require("../../assets/images/google-play.png")}
						/>
					</a>
				) : (
					<a title="Aplicativo não disponível">
						<img
							src={require("../../assets/images/google-play.png")}
							style={{ filter: "brightness(0.5)" }}
						/>
					</a>
				)}
				{!!theme.mainSection.appleStoreLink ? (
					<a
						href={theme.mainSection.appleStoreLink}
						target="_blank"
						rel="noopener noreferrer"
						title="Baixar na app store"
					>
						<img
							src={require("../../assets/images/app-store.png")}
						/>
					</a>
				) : (
					<a title="Aplicativo não disponível">
						<img
							src={require("../../assets/images/app-store.png")}
							style={{ filter: "brightness(0.5)" }}
						/>
					</a>
				)}
			</div>
		</div>
	</section>
);
