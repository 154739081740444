import * as React from "react";

import { Layout } from "../templates/layout";
import { SEO } from "../components/Seo";
import { MainSection } from "../containers/MainSection";
import { ServicesSection } from "../containers/ServicesSection";
import { OtherServicesSection } from "../containers/OtherServicesSection";

const IndexPage = () => {
	return (
		<Layout>
			<SEO keywords={[`gatsby`, `application`, `react`]} />
			<MainSection />
			<ServicesSection />
			<OtherServicesSection />
		</Layout>
	);
};

export default IndexPage;
