/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import * as React from "react";
import Helmet from "react-helmet";
// import { useStaticQuery, graphql } from "gatsby";

import theme from "../../themeConfig";

interface IMeta {
	name: string;
	content: string;
}

interface IProps {
	description?: string;
	lang?: string;
	meta: IMeta[];
	keywords: string[];
	title?: string;
}

export function SEO({
	/* description,*/ lang,
	meta,
	keywords /*, title*/,
}: IProps) {
	// const { site } = useStaticQuery(
	// 	graphql`
	// 		query {
	// 			site {
	// 				siteMetadata {
	// 					title
	// 					description
	// 					author
	// 				}
	// 			}
	// 		}
	// 	`,
	// );

	const metaDescription = theme.metaDescription; // description || site.siteMetadata.description;

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={theme.pageTitle}
			titleTemplate={`%s`}
			meta={[
				{
					name: `description`,
					content: metaDescription,
				},
				{
					property: `og:title`,
					content: theme.pageTitle,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				// {
				// 	name: `twitter:card`,
				// 	content: `summary`,
				// },
				// {
				// 	name: `twitter:creator`,
				// 	content: site.siteMetadata.author,
				// },
				// {
				// 	name: `twitter:title`,
				// 	content: title,
				// },
				// {
				// 	name: `twitter:description`,
				// 	content: metaDescription,
				// },
			]
				.concat(
					keywords.length > 0
						? {
								name: `keywords`,
								content: keywords.join(`, `),
						  }
						: [],
				)
				.concat(meta)}
		/>
	);
}

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	keywords: [],
	description: ``,
};
