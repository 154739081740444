import * as React from "react";

export const WalletSvg = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 150.31 153.38"
		width="1em"
		height="1em"
		fill="currentColor"
	>
		<defs>
			<clipPath id="a">
				<path d="M0 0h150.31v153.38H0z" />
			</clipPath>
		</defs>
		<g clipPath="url(#a)">
			<path d="M75.1 0c-.62-.01-1.21.17-1.7.53L38.12 26.14H11.74c-5.27 0-9.76 3.4-11.23 8.03-.34.45-.52 1-.51 1.56v97.6c0 11.06 9.32 20.05 20.8 20.05h90.38c5.73 0 10.93-2.25 14.7-5.88a19.99 19.99 0 0 0 4.8-7.3h7.62c6.46 0 11.75-5.1 11.75-11.32v-.06c-.6-30.21.83-61.07 0-91.36 0-6.23-5.3-11.32-11.75-11.32h-24.2l-8.13-15.96a2.72 2.72 0 0 0-2.4-1.47c-.42 0-.84.1-1.22.27L88.5 15.53 77.2 1.04A2.74 2.74 0 0 0 75.09 0zm-.6 6.26l9.05 11.6L45.41 35.9l-15.65 7.4H23.5L74.5 6.26zm27.8 8.56l7.57 14.89.1.2 7.42 14.58h-.03a21.39 21.39 0 0 0-3.3-.7 5.7 5.7 0 0 0-1.48-.21H41.5l1.66-.79 59.13-27.97zM11.74 31.37h19.18l-12.68 9.21-4.12 3h-2.38a6.15 6.15 0 0 1-6.32-6.11c0-3.43 2.77-6.1 6.32-6.1zm105.01 0h21.55a6.14 6.14 0 0 1 6.32 6.17c.82 30.04-.6 60.88 0 91.34 0 3.42-2.77 6.1-6.32 6.1h-6.42c.05-.54.09-1.1.09-1.65V63.61c0-5.69-2.48-10.81-6.44-14.46a2.51 2.51 0 0 0-.25-1l-.02-.05-8.5-16.73zM5.42 46.99c1.83 1.14 4 1.81 6.32 1.81h99.43c8.56 0 15.38 6.56 15.38 14.8v69.73c0 .61-.05 1.22-.13 1.83v.04a15.67 15.67 0 0 1-.37 1.87c-1.7 6.4-7.66 11.08-14.88 11.08H20.8c-8.56 0-15.37-6.57-15.37-14.82V46.99zM112.2 87.14c-6.08 0-10.72 5.27-10.72 11.33 0 6.07 4.64 11.33 10.72 11.33 6.07 0 10.72-5.26 10.72-11.33 0-6.06-4.65-11.33-10.72-11.33zm0 5.24c2.77 0 5.3 2.53 5.3 6.1 0 3.55-2.53 6.1-5.3 6.1s-5.3-2.55-5.3-6.1c0-3.57 2.53-6.1 5.3-6.1z" />
		</g>
	</svg>
);
