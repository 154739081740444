import * as React from "react";
import classNames from "classnames";
import s from "./style.module.scss";

interface IProps {
	onClick?: () => void;
	className?: string;
}

export const IconButton: React.FunctionComponent<IProps> = (props) => (
	<button
		className={classNames(s.iconBtn, props.className)}
		onClick={props.onClick}
	>
		{props.children}
	</button>
);
