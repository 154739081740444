import * as React from "react";

export const ChatHelpSvg = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 512 512"
		width="1em"
		height="1em"
		fill="currentColor"
	>
		<path d="M253.81 158.16c-31.05 0-45.3 18.4-45.3 30.83 0 8.98 7.58 13.12 13.8 13.12 12.41 0 7.35-17.72 30.81-17.72 11.5 0 20.7 5.06 20.7 15.65 0 12.42-12.88 19.56-20.47 26-6.67 5.75-15.4 15.19-15.4 34.98 0 11.96 3.21 15.42 12.64 15.42 11.27 0 13.57-5.07 13.57-9.44 0-11.96.23-18.87 12.88-28.76 6.21-4.84 25.77-20.48 25.77-42.12 0-21.63-19.55-37.97-49-37.97zM250.82 292.32c-9.66 0-17.48 8.05-17.48 17.72 0 9.43 7.6 17.71 17.48 17.71 9.9 0 17.72-8.28 17.72-17.71 0-9.67-8.06-17.72-17.72-17.72z" />
		<path d="M256 0C122.24 0 14 108.29 14 242.1a241.2 241.2 0 0 0 65.48 165.6l-50.06 81.38c-7.81 12.7 5.8 27.8 19.23 21.4l103.8-49.5A239.24 239.24 0 0 0 256 484.22c133.76 0 242-108.29 242-242.1C498 108.34 389.83 0 256 0zm0 454.21a209.6 209.6 0 0 1-96.6-23.24 15 15 0 0 0-13.3-.2l-64.52 30.78 29.6-48.14a15 15 0 0 0-2.35-18.65C67.03 354.41 44 300.2 44 242.1 44 125.15 139.1 30 256 30s212 95.15 212 212.1-95.1 212.11-212 212.11z" />
	</svg>
);
