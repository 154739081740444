import * as React from "react";

export const ArrowsSvg = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 34.41 19.7"
		width="1em"
		height="1em"
		fill="currentColor"
	>
		<g fillRule="evenodd">
			<path d="M28.07 1.84L29.19.7l4.52 4.51-4.51 4.51-1.13-1.12L30.66 6H9.78v-1.6h20.87zM5.22 9.97L.7 14.5l4.5 4.5 1.14-1.12-2.6-2.59h20.89v-1.6H3.76l2.59-2.58z" />
		</g>
	</svg>
);
